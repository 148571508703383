import React from "react"
import LayoutXT from "../components/LayoutXT"

export default function Pflichtteil() {
  return (
    <LayoutXT>
      <h1 className="left">Pflichtteilsrecht / Pflichtteilsverzicht</h1>
      <div className="rem-of-13 gray-a lheight18">
        Das Pflichtteilsrecht sichert Ehegatten, Kindern oder bei kinderlosen
        Ehegatten den Eltern des Erblassers eine Mindestbeteiligung am Nachlass
        des Erblassers. Bei der individuell ausgestalteten Nachlassplanung,
        insbesondere bei dem Übergang einer Immobilie oder eines Betriebs, sind
        mögliche Pflichtteilsansprüche stets zu berücksichtigen, denn wer in
        einem Testament, aus welchem Grund auch immer, nicht als Erbe eingesetzt
        wird, ist enterbt.
        <br />
        <br />
        Wir beraten Sie zu allen Fragen des Pflichtteilsrechts, so ob es im
        Einzelfall sinnvoll ist, mit einzelnen pflichtteilsberechtigten Erben
        z.B. einen Verzicht auf den Pflichtteil zu vereinbaren. Auf diesem Wege
        kann bereits zu Lebzeiten sichergestellt werden, dass Haus und Grund
        nicht wegen Pflichtteilsansprüchen von Abkömmlingen veräußert werden
        müssen. Auch der Betrieb eines Unternehmens sollte nicht mit den teils
        sehr hohen Pflichtteilszahlungen belastet sein. <br />
        <br />
        Aber auch aus der Perspektive eines enterbten Pflichtteilsberechtigten
        beraten und begleiten wir Sie gerne, insbesondere wenn es darum geht,
        dass Sie Ihre Teilhabe am Nachlass des Erblassers geltend machen. Wir
        empfehlen, dass Pflichtteilsansprüche frühzeitig überdacht werden, denn
        ein Pflichtteilsanspruch verjährt bereits nach drei Jahren ab dem
        Zeitpunkt der Kenntnis des Todesfalls und der eigenen
        Pflichtteilsberechtigung.
        <br />
        <br />
        Ziel ist selbstverständlich eine gütliche und außergerichtliche Einigung
        mit den Erben, doch notfalls werden wir Ihren Anspruch auch vor dem
        Nachlassgericht konsequent durchsetzen.
        <br />
        <br />
        Für den Fall, dass der Erblasser noch zu Lebzeiten größere Teile sein
        Vermögen verschenkt hat, ist auch ein Pflichtteilsergänzungsanspruch zu
        prüfen. Ferner empfehlen wir eine fachkundige Beratung, wenn Geschwister
        zu Lebzeiten des Erblassers Geld oder eine Ausstattung erhalten haben.
        Auch die Bewertung Ihrer Pflichtteilsansprüche zählt zu unserem
        Leistungsspektrum, in Zusammenarbeit mit ausgewählten, unabhängigen und
        öffentlich vereidigten Sachverständigen aus der Region.
      </div>
    </LayoutXT>
  )
}
