import React from "react"
import { Helmet } from "react-helmet"

// the css
import "../styles/style.css"

// title differs dependent on current page
function dynTitle() {
  const path = typeof window !== "undefined" ? window.location.pathname : "/"
  if (path === "/erbfolge/")
    return "Erbfolge und Nachlassplanung durch Testament"
  if (path === "/pflichtteil/")
    return "Pflichtteilsrecht / Pflichtteilsverzicht"
  if (path === "/auseinandersetzung/")
    return "Auseinandersetzung einer Erbengemeinschaft"  
  if (path === "/nachlassgericht/")
    return "Vertretung vor dem Finanzamt oder dem Nachlassgericht / Schwarzgeldkonten"
  if (path === "/uebergabe/")
    return "Vermögensübergabe zu Lebzeiten"
  if (path === "/erbschein/")
    return "Erbschein"
  if (path === "/vorsorge/")
    return "Vorsorgevollmacht, Betreuungsverfügung, Patientenverfügung"
  if (path === "/immobilienrecht/")
    return "Grundstücksrecht & Immobilienrecht"
    
  return "Kanzlei Schweikert"
}

export default function LayoutXT({ children }) {
  return (
    <>
      <Helmet>
        <title>{dynTitle()}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="author"
          content="Rechtsanwalt Schweikert, Lagerhausstr.12, 83043 Bad Aibling"
        />
        <meta name="copyright" content="Kanzlei Schweikert" />
        <meta
          name="description"
          content=""
        />
        <meta
          name="keywords"
          content=""
        />
        <meta name="dcterms.created" content="Fr, 27 Feb 2015 20:26:25 GMT" />
        <meta name="page-topic" content="Recht" />
        <meta name="page-type" content="Anzeige" />
        <meta name="publisher" content="Rechtsanwalt Friedrich Schweikert" />
        <meta name="revisit-after" content="30 days" />
        <meta name="robots" content=" INDEX,FOLLOW" />
        <meta name="geo.region" content="DE-BY" />
        <meta name="geo.placename" content="Bad Aibling" />
        <meta name="geo.position" content="47.860711;12.001394" />
        <meta name="ICBM" content="47.860711, 12.001394" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.png" />
      </Helmet>
      {children}
    </>
  )
}
